import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = (theme) => ({
    root: {
        marginTop: theme.spacing(4)
    },
    header: {
        fontSize: '28px',
        textAlign: 'center',
        color: '#555'
    },
    text: {
        fontSize: '15px'
    },
    boxHeader: {
        marginLeft: '7%',
        fontSize: '20px',
        fontWeight: 'bold'
    },
    table: {
        fontSize: '15px',
        marginTop: '1%',
        marginLeft: '20%'
    },
    tableRow: {
        marginTop: '500px'
    },
    tableText: {
        color: 'white'
    },
    qrText: {
        fontSize: '12px',
        textAlign: 'center'
    }
});

class Instructions extends React.Component {
    constructor(props) {
        super(props);

        if (
            !this.props.location.state ||
            !this.props.location.state.email ||
            !this.props.location.state.company ||
            !this.props.location.state.locationHeader
        ) {
            // Set the state blank, otherwise the render blows up
            this.state = { email: '', company: '', login_link: '' };
            this.props.history.replace('/');
        } else {
            this.state = {
                email: this.props.location.state.email,
                company: this.props.location.state.company,
                login_link: this.determineLoginLink(this.props.location.state.locationHeader)
            };
        }
    }

    determineLoginLink = (location) => {
        const companyGuid = location.substring(location.lastIndexOf('/') + 1);
        const loginLink = `https://cloud.privakey.com/portal/login?guid=${companyGuid}`;
        return loginLink;
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container justify="center" spacing={3}>
                    <Grid item sm={10} xs={12}>
                        <Typography className={classes.header}>Welcome to</Typography>
                        <img
                            src={'/logo.png'}
                            style={{
                                height: '50px',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                            alt="privakey text"
                        />
                        <br />
                        <Typography className={classes.text}>
                            We are thrilled you'll be using Privakey's Cloud Transaction Intent
                            Verification tool. Thank You.
                        </Typography>
                        <br />
                        <Typography className={classes.text}>
                            Your account has been created but there are few things you need to do to
                            gain access.{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                First - please review the account information below.
                            </span>{' '}
                            If any account details are incorrect please contact us at{' '}
                            <a href="mailto: support@privakey.com">support@privakey.com</a>.
                        </Typography>
                        <br />
                        <Box
                            style={{
                                backgroundColor: '#333',
                                height: '160px',
                                color: '#f27901',
                                borderRadius: '4px'
                            }}>
                            <br />
                            <Typography className={classes.boxHeader}>
                                Review your Account Information
                            </Typography>
                            <table className={classes.table}>
                                <tbody>
                                    <tr>
                                        <td>
                                            Company Name:{' '}
                                            <span className={classes.tableText}>
                                                {this.state.company}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Admin Email:{' '}
                                            <span className={classes.tableText}>
                                                {this.state.email}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Login Link:{' '}
                                            <span className={classes.tableText}>
                                                {this.state.login_link}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Box>
                        <br />
                        <Typography className={classes.text}>
                            Next you'll need to set up Privakey's Transaction Intent Verification
                            application to access your Admin console by following the steps below.
                        </Typography>
                        <ol>
                            <li>
                                <Typography className={classes.text}>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Download the Privakey AuthWallet App,
                                    </span>{' '}
                                    available for{' '}
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://apps.apple.com/us/app/authwallet/id1552057206">
                                        iOS
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://play.google.com/store/apps/details?id=com.privakey.authwallet">
                                        Android
                                    </a>
                                    . This application is required to authenticate to the Portal
                                    (and can also be used by your services' users once you've
                                    launched your Privakey-enabled solution). For more detailed
                                    information, please read the{' '}
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://tech.privakey.com/cloudAdoption/pkCloudGettingStarted">
                                        Quick Start Guide
                                    </a>
                                    .
                                </Typography>
                            </li>
                            <br />
                            <li>
                                <Typography className={classes.text}>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Bind your New App to the Admin Server.
                                    </span>{' '}
                                    Binding your account to an app instance is easy. You should have
                                    received an email with further instructions. After installing
                                    the app, follow the instructions in the email and you will be
                                    ready to go! Didn't receive an email? Either try the sign-up
                                    process again or contact us at{' '}
                                    <a href="mailto: support@privakey.com">support@privakey.com</a>.
                                </Typography>
                            </li>
                        </ol>
                        <br />
                        <br />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(useStyles)(Instructions);
