import React from 'react';
import Register from './Register.js';
import Instructions from './Instructions.js';
import { Route, Switch } from 'react-router-dom';

function App() {
    return (
        <main>
            <Switch>
                <Route path="/" component={Register} exact />
                <Route path="/confirmation" component={Instructions} exact />
            </Switch>
        </main>
    )
}

export default App;