import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './App.css';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';

const useStyles = (theme) => ({
    root: {
        marginTop: theme.spacing(4)
    },
    header: {
        fontWeight: 'bold',
        fontSize: '18px',
        textAlign: 'center'
    },
    text: {
        fontSize: '14px'
    },
    helper: {
        color: '#FF0000'
    }
});

class Register extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            company: '',
            phone_number: '',
            error_first_name: '',
            error_last_name: '',
            error_email: '',
            error_company: '',
            error_phone_number: '',
            moveToInstructions: false,
            accessToken: ''
        };
    }

    updateInfo = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    isFormValid = () => {
        // Reset error state
        this.setState({
            error_first_name: '',
            error_last_name: '',
            error_email: '',
            error_company: '',
            error_phone_number: ''
        });

        let isValid = true;

        if (this.state.first_name === '') {
            this.setState({ error_first_name: 'Please enter a valid first name' });
            isValid = false;
        }

        if (this.state.last_name === '') {
            this.setState({ error_last_name: 'Please enter a valid last name' });
            isValid = false;
        }

        if (this.state.email === '' || !this.state.email.includes('@')) {
            this.setState({ error_email: 'Please enter a valid email' });
            isValid = false;
        }

        if (this.state.company === '') {
            this.setState({ error_company: 'Please enter a valid company name' });
            isValid = false;
        }

        // Phone number regex, handles international numbers with a + at the beginning
        let re_number = /^(\+\d{1,2}(\s)?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        if (this.state.phone_number === '') {
            this.setState({ error_phone_number: 'Please enter a valid phone number' });
            isValid = false;
        } else if (!re_number.test(this.state.phone_number)) {
            this.setState({
                error_phone_number:
                    'Please enter a valid phone number. (Having trouble? Try removing spaces or dashes)'
            });
            isValid = false;
        }

        return isValid;
    };

    submitForm = () => {
        if (this.isFormValid()) {
            this.isSubmitting = true;
            let data = {
                companyName: this.state.company,
                adminEmail: this.state.email,
                adminFirstName: this.state.first_name,
                adminLastName: this.state.last_name,
                adminPhoneNumber: this.state.phone_number
            };
            $.ajax({
                url: '/api/signup',
                type: 'post',
                data,
                success: (data, status, xhr) => {
                    this.setState({
                        moveToInstructions: true,
                        locationHeader: xhr.getResponseHeader('location')
                    });
                    this.isSubmitting = false;
                },
                error: () => {
                    this.isSubmitting = false;
                }
            });
        }
    };

    // checkKeydown & checkKeyup are used together to ensure that the form is only submitted once,
    // because keydown fires multiple times per keystroke (sometimes up to 100 times).
    checkKeydownForEnter = (event) => {
        if (
            event !== undefined &&
            event.originalEvent !== undefined &&
            event.originalEvent.key === 'Enter' &&
            this.isSubmitting !== true &&
            this.isPressingEnter !== true
        ) {
            this.isPressingEnter = true;
            this.submitForm();
        }
    };

    checkKeyupForEnter = (event) => {
        if (
            event !== undefined &&
            event.originalEvent !== undefined &&
            event.originalEvent.key === 'Enter' &&
            this.isPressingEnter === true
        ) {
            this.isPressingEnter = false;
        }
    };

    render() {
        const { classes } = this.props;

        // Every input field should listen for events, so they can handle submitting the form.
        // It probably would have been easier just to convert the form to an actual form...
        let ids = ['#first_name', '#last_name', '#email', '#company', '#phone_number'];
        ids.map((id) => {
            $(id).on('keydown', (event) => this.checkKeydownForEnter(event));
            $(id).on('keyup', (event) => this.checkKeyupForEnter(event));
            return undefined;
        });

        if (this.state.moveToInstructions) {
            return (
                <Redirect
                    from="/"
                    to={{
                        pathname: '/confirmation',
                        state: {
                            email: this.state.email,
                            company: this.state.company,
                            locationHeader: this.state.locationHeader
                        }
                    }}
                />
            );
        }

        return (
            <div className={classes.root}>
                <Grid container justify="center" spacing={3}>
                    <Grid item sm={8} xs={12}>
                        <img
                            src={'/PrivakeyLogo.png'}
                            style={{
                                height: '50px',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                            alt=""
                        />
                        <br />
                        <Typography className={classes.header}>
                            Sign up for a free Privakey Cloud account
                        </Typography>
                        <br />
                        <Typography className={classes.text}>
                            Privakey Cloud is the easiest way to add passwordless authentication,
                            multi-factor authentication and transaction intent verification to your
                            service. The Privakey Cloud offering is Free. For more information or to
                            learn about or more flexible licensed offerings please contact{' '}
                            <a href="mailto:sales@privakey.com">our sales team</a>. Any questions on
                            using Privakey? Contact{' '}
                            <a href="mailto:support@privakey.com">support</a>.
                        </Typography>
                        <br />
                        <Typography className={classes.text}>
                            Use the following form to create your company and user account. After
                            filling out the form you will receive instructions via email on how to
                            download the Privakey App. These instructions will also be presented on
                            the next page after submitting the form. The Privakey App, AuthWallet,
                            is required to authenticate to the Portal. AuthWallet is available on{' '}
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://play.google.com/store/apps/details?id=com.privakey.authwallet">
                                Google Play
                            </a>{' '}
                            and the{' '}
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://apps.apple.com/us/app/authwallet/id1552057206">
                                App Store
                            </a>
                            . For more information, please read the{' '}
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://tech.privakey.com/cloudAdoption/pkCloudGettingStarted">
                                Quick Start Guide
                            </a>
                            .
                        </Typography>
                    </Grid>
                </Grid>
                <br />
                <Grid container justify="center" spacing={3}>
                    <Grid item sm={8} xs={12}>
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            name="first_name"
                            id="first_name"
                            label="First Name"
                            value={this.state.first_name}
                            onChange={this.updateInfo}
                            FormHelperTextProps={{ className: classes.helper }}
                            helperText={this.state.error_first_name}
                            InputProps={{
                                classes: {
                                    input: classes.text
                                }
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.text
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={3}>
                    <Grid item sm={8} xs={12}>
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            name="last_name"
                            id="last_name"
                            label="Last Name"
                            value={this.state.last_name}
                            onChange={this.updateInfo}
                            FormHelperTextProps={{ className: classes.helper }}
                            helperText={this.state.error_last_name}
                            InputProps={{
                                classes: {
                                    input: classes.text
                                }
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.text
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={3}>
                    <Grid item sm={8} xs={12}>
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            name="email"
                            id="email"
                            label="Email"
                            value={this.state.email}
                            onChange={this.updateInfo}
                            FormHelperTextProps={{ className: classes.helper }}
                            helperText={this.state.error_email}
                            InputProps={{
                                classes: {
                                    input: classes.text
                                }
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.text
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={3}>
                    <Grid item sm={8} xs={12}>
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            name="company"
                            id="company"
                            label="Company"
                            value={this.state.company}
                            onChange={this.updateInfo}
                            FormHelperTextProps={{ className: classes.helper }}
                            helperText={this.state.error_company}
                            InputProps={{
                                classes: {
                                    input: classes.text
                                }
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.text
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={3}>
                    <Grid item sm={8} xs={12}>
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            name="phone_number"
                            id="phone_number"
                            label="Phone Number"
                            value={this.state.phone_number}
                            onChange={this.updateInfo}
                            FormHelperTextProps={{ className: classes.helper }}
                            helperText={this.state.error_phone_number}
                            InputProps={{
                                classes: {
                                    input: classes.text
                                }
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.text
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container justify="center" spacing={3}>
                    <Grid item sm={4} xs={12}>
                        <Button
                            style={{
                                backgroundColor: '#f27901',
                                color: 'white',
                                fontSize: '15px',
                                fontWeight: 'bold'
                            }}
                            fullWidth
                            variant="contained"
                            className={classes.text}
                            onClick={this.submitForm}>
                            Register
                        </Button>
                    </Grid>
                </Grid>
                <br />
            </div>
        );
    }
}

export default withStyles(useStyles)(Register);
